<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col>
				<Breadcrumb :labels="labels" />
			</b-col>
			<b-col>
				<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
					<b-form-group class="mb-0">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" size="17" />
							</b-input-group-prepend>
							<b-form-input placeholder="Search" @input="searchValue" />
						</b-input-group>
					</b-form-group>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div>
					<b-tabs card class="caption-add table-custom" v-model="tabIndexStaff">
						<b-tab title="Staff" class="staff-tab">
							<b-card class="px-table-0 mb-3 w-15-percent-cl-2 w-15-percent-cl-3 w-25-percent-cl-4">
								<b-row class="align-center">
									<b-col>
										<div class="text-right mb-2 pr-2">
											<template v-if="(userData && permission.create)">
												<b-button type="button" variant="primary" class="btn-df size-18 black"
													@click="addStaff">
													Add Staff
												</b-button>
											</template>
											<template v-else>
												<b-button type="button" variant="primary"
													class="btn-df size-18 black btn-disabled-gray" disabled>
													Add Staff
												</b-button>
											</template>
										</div>
									</b-col>
								</b-row>
								<b-table small :fields="fields" :items="itemsData"
									tbody-tr-class="size-14 table-row cursor-pointer" show-empty @row-clicked="detailStaff">
									<template #head(id)="data">
										<span>{{ data.label }}</span>
									</template>
									<template #head(full_name)="data">
										<span>{{ data.label }}</span>
									</template>
									<template #head()="data">
										<span class="d-block text-center">{{ data.label }}</span>
									</template>
									<template #cell(id)="data">
										<span>
											<span>ST</span>
											<span v-if="data.item.id <= 9">000</span><span
												v-else-if="data.item.id > 9 && data.item.id <= 99">00</span><span
												v-else-if="data.item.id > 99 && data.item.id <= 999">0</span>{{
													data.item.id }}
										</span>
									</template>
									<template #cell(full_name)="data">
										<span class="maxTwoRow">{{ data.item.full_name }}</span>
									</template>
									<template #cell(username)="data">
										<span class="maxTwoRow text-center">{{ data.item.username }}</span>
									</template>
									<template #cell(role)="data">
										<template v-for="item in data.value">
											<span class="text-center d-block">{{ item.name }}</span>
										</template>
									</template>
									<template #cell(status)="data">
										<div class="d-block text-center">
											<span v-if="data.item.active == 0">Inactive</span>
											<span v-else-if="data.item.active == 1">Active</span>
										</div>
									</template>
									<template #cell(action)="data">
										<div class="d-block text-center">
											<template v-if="data.item.id == 1">
												<span class="px-05">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
															fill="#b1b1b1" />
													</svg>
												</span>
												<span class="px-05">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd"
															d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
															fill="#b1b1b1" />
													</svg>
												</span>
											</template>
											<template v-else>
												<template v-if="(userData && permission.edit  && data.item.permission > 0)">
													<span class="px-05 cursor-pointer"
														@click.stop="editStaff(data.item.id)">
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
																fill="#8471FF" />
														</svg>
													</span>
												</template>
												<template v-else>
													<span class="px-05">
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path
																d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
																fill="#b1b1b1" />
														</svg>
													</span>
												</template>
												<template v-if="(userData && permission.delete && data.item.permission > 0)">
													<span class="px-05 cursor-pointer"
														@click.stop="showDeleteAction(data.item.id, data.item.full_name)">
														<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd"
																d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
																fill="#DD3E47" />
														</svg>
													</span>
												</template>
												<template v-else>
													<span class="px-05">
														<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
															xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd"
																d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
																fill="#b1b1b1" />
														</svg>
													</span>
												</template>
											</template>
											<!-- </template>
											<template v-else>
												<span class="px-05">
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
													</svg>
												</span>
												<span class="px-05">
													<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
													</svg>
												</span>
											</template> -->
										</div>
									</template>
									<template #cell()="data">
										<span class="d-block text-center">{{ data.value }}</span>
									</template>
									<template #empty="scope">
										<template v-if="getSearch">
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data found
											</h4>
										</template>
										<template v-else>
											<h4 class="my-3 text-center"
												style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
										</template>
									</template>
								</b-table>
							</b-card>
							<div v-if="this.total > 0" class="paging-cus relative">
								<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
									aria-controls="custom-table" first-number last-number
									class="just-center"></b-pagination>
								<div class="limit-per">
									<select v-model="selected" class="cursor-pointer">
										<option v-for="limit in limitpage">{{ limit }}</option>
									</select>
								</div>
							</div>
						</b-tab>
						<b-tab title="TVET Experts"
							class="tab-child w-10-percent-cl-1 w-20-percent-cl-2 w-25-percent-cl-3 w-20-percent-cl-4">
							<b-tabs card class="caption-add table-custom" v-model="tabIndex">
								<b-tab title="External">
									<External ref="tab_0" :search="getSearch" />
								</b-tab>
								<b-tab title="Ex-ITE">
									<ExITE ref="tab_1" :search="getSearch" />
								</b-tab>
								<b-tab title="Currently ITE">
									<ITE ref="tab_2" :search="getSearch" />
								</b-tab>
							</b-tabs>
						</b-tab>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
		<b-modal id="modalDeleteSystemStaff" ref="modal_deleteSystemStaff" title="System Message" hide-header-close
			ok-title="Save" centered>
			<div class="d-block text-center titleModalDelete">
				<b>Delete {{ staffDelete.nameDelete }} ?</b>
			</div>
			<div class="d-block text-center">
				<span>Deleting a staff will remove them from all past [Object]s data will no longer be available, would you
					like to proceed?</span>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelDelete">
					Cancel
				</b-button>
				<b-button size="sm" variant="primary" class="btn-primary mb-1" @click="btnProceed">
					Proceed
				</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>

import Breadcrumb from '../partial/Breadcrumb'
import External from './External/List'
import ExITE from './Ex-ITE/List'
import ITE from './ITE/List'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import globalAdmin from '../model/globalAdmin'
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		External,
		ExITE,
		ITE,
		ToastificationContent,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			labels: { "staff-management": "" },
			fields: [
				{ key: 'id', label: 'STID' },
				{ key: 'full_name', label: 'Staff Name' },
				{ key: 'username', label: 'Username' },
				{ key: 'email', label: 'Email' },
				{ key: 'role', label: 'Role' },
				{ key: 'status', label: 'Status' },
				{ key: 'action', label: 'Actions' },
			],
			permission: [],
			itemsData: [],
			getSearch: null,
			timer: null,
			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',
			tabIndexStaff: 0,
			tabIndex: 0,
			roleUser: null, //( 1 supper admin, 2 admin, 3 managemant, 4 staff)
			staffDelete: {
				nameDelete: null,
				idDelete: null
			}

		}
	},
	mounted() {
		this.selected = '10'
		this.$nextTick(() => {
			this.loadDataToTab(this.getSearch)
		})
	},
	watch: {
		getSearch(val) {
			this.fetchList(val)
			this.loadDataToTab(val)
		},
		currentPage(val) {
			this.fetchList(this.getSearch)
		},
		tabIndex: function (val) {
			this.loadDataToTab(this.getSearch)
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.fetchList(this.getSearch)
			}
		}
	},
	created() {
		this.permission = this.checkPermission(this.userData, "Staff Management");
		
		if (this.$route && this.$route.query && this.$route.query.tab) this.tabIndexStaff = Number(this.$route.query.tab)
		if (this.$route && this.$route.query && this.$route.query.type) this.tabIndex = Number(this.$route.query.type)
		this.fetchList(this.getSearch)
	},
	methods: {
		fetchList(valueSearch) {

			this.$store
				.dispatch('auth/fetch_staff_list', {
					filter: { "search": valueSearch },
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {
					this.itemsData = response.data.data.data
					this.total = response.data.data.total
					// console.log('staff list: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		searchValue(val) {
			this.getSearch = val
		},
		loadDataToTab(val) {
			this.$refs[`tab_${this.tabIndex}`].currentPage = 1
			this.$refs[`tab_${this.tabIndex}`].fetchList(val)
		},
		detailStaff(item) {
			if (item.permission != 1) {
				this.$router.push(`/admin/staff-management/${item.id}`)
				return
			}
			this.$router.push(`/admin/staff-management/${item.id}`)
		},
		editStaff(id) {
			this.$router.push(`/admin/staff-management/edit/${id}`)
		},
		addStaff() {
			this.$router.push(`/admin/staff-management/add-staff`)
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this item?`, {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.functionDelete(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		cancelDelete() {
			this.staffDelete.idDelete = null
			this.staffDelete.nameDelete = null
			this.$refs['modal_deleteSystemStaff'].hide()
		},
		btnProceed() {
			this.functionDelete(this.staffDelete.idDelete, this.staffDelete.nameDelete, "true")
		},
		functionDelete(id, title, proceed = null) {
			this.$store
				.dispatch('auth/deleteStaff', {
					id: id,
					is_delete: proceed
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.$refs['modal_deleteSystemStaff'].hide()
					this.fetchList(this.getSearch)
				})
				.catch((error) => {
					if (error.response.status == 406) {
						this.staffDelete.idDelete = id
						this.staffDelete.nameDelete = title
						this.$refs['modal_deleteSystemStaff'].show()
						return
					}
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})



				})
		}
	},
};

</script>

<style lang="scss">
@import "@/assets/scss/_ite_tab_table.scss";

.titleModalDelete {
	size: 18px;
	font-weight: 700;
	padding-bottom: 20px;
}

.description {
	size: 18px;
	font-weight: 400;
}
</style>